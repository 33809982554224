.projects-background {
  padding: 2em;
  min-height: 100vh;
  background-image: url("./project-bg.jpg");
}

.dark-title{
  color: white !important;
  display: flex;
}

.slick-slider{
  padding-top: 0em;
}

.card-custom{
  margin: 1em;
}

.MuiGrid-item{
  padding: 0em
}

.project-tag {
  margin: 0.25em
}

.tags-space{
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  justify-content: center;
}

.project-desc-title{
  padding-left: 0.5em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.25em;
}
.project-subtitle{
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.25em;
  padding-bottom: 0.5em;
}
.project-desc-text{
  text-align: justify;
}

/* remove padding from modal */
.styles_modal__gNwvD {
  padding: 0em !important;
}

.view-all{
  margin-left: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: white;
}

.view-all-text{
  font-size: 1em !important;
  font-weight: bold !important;
}
