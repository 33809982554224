.skills-background {
  padding: 2em;
  min-height: 100vh;
  background-color: #414141;
}

.table-container {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.table {

}

.skill-cell {
  vertical-align: middle !important;
  background-color: #414141;
}
