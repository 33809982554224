.experience-background {
  background-image: url("./webb-dark.png");
  border-top: 5px;
  border-color: white;
  padding: 2em;
}

.vertical-timeline {
  margin-top: 0 !important;
}

.vertical-timeline-element--work {

}

.vertical-timeline-element-date {
  color: white !important;
  font-size: large;
  opacity: 1 !important;
}

.vertical-timeline-element-title {
  font-size: 1.25em;
}

.vertical-timeline-element-subtitle {
  font-size: 1em !important;
  color: gray !important;
  font-style: italic !important;
  margin-bottom: 0.7rem !important;
}

.list{
  box-shadow: none !important;
}

.list-item{
  border-bottom: none !important;
}
