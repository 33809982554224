.landing-background {
  background-color: black;
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  color: white;
  text-align: center;
  position: absolute;
  margin: 1em;
  z-index: 1;
}

.profile-pic {
  max-width: 200px;
  max-height: 200px;
}

.intro {
  font-size: xx-large;
  font-weight: bold;
}

.faded-text {
  font-size: x-large;
  font-weight: normal;
  margin-top: 0.75em;
}

.social-icons {
  font-size: xx-large;
}

.social-icon {
  margin: 0.5em;
  color: white;
}

.animated-canvas {
  background-color: #0f2a3e;
  width: 100vw;
  height: 100vh;
  min-width: inherit;
  min-height: inherit;
  z-index: 0;
  /* position: fixed; */
}
