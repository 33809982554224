.summary-background {
  background-color: white;
  /* background-image: url("./wp2533042.jpg"); */
  color: white !important;
  background-color: #414141;
}

.summary-content {
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  padding-bottom: 2em;
  min-height: 100vh;
}

.tagline {
  margin-top: 1em;
  font-size: 32px;
  border-color: white;
  padding-left: 1em;
}

.What-I-do-title {
  margin-bottom: 1em;
  border: 2px;
  border-style: solid;
  boreder-color: white;
}

.icons {
  text-align: center !important;
}

.what-I-do-icons {
  font-size: 96px;
  padding-bottom: 0.2em;
  margin-top: 0.5em;
}

.desc {
  margin: 1rem ;
  margin-top: 1em !important;
  padding: 1em ;
  padding-top: 1.5em !important;
  color: white !important;
}

.quote {
  font-size: x-large;
  font-variant: petite-caps;
  font-style: italic;
  padding: 1rem;
  margin: 0rem;
}

.next-para{
  padding-top: 1em !important;
}

.workinfo {
  font-size: x-large;
  text-align: justify;
  padding: 1rem;
  margin: 0rem;
  /* font-family: monospace; */
}

.hobbyinfo {
  text-align: justify;
  padding: 1rem;
  margin: 0rem;
}

.code {
    color: #00ff00;
}
