.certifications-background {
  background-image: url("./images.jpg");
  padding: 2em;
  border-top: 5px;
  border-color: white;
  min-height: 100vh;
  color: black;
}

.certifications-title {
  background-color: #2255AA;
  color: white !important;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 0.5em;
  border: 1;
  border-style: solid;
  border-color: white;
  font-size: x-large;
}

.issued-by{
  color: gray;
  font-style: italic;
  font-size: 0.75em;
}
